import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const REGULAR_HEIGHT = 160;
const MULTI_HEIGHT = 400;
const MAX_TRACKLIST_TRIES = 100

class Player extends React.Component {

  constructor(props) {
    super(props);
    this.widgetEl = React.createRef();
    this.widget = null;
    this.libLoaded = false;
    this.frameLoaded = false;
    this.tracklistRetry = null;
    this.retries = 0;

    this.state = {
      sounds: [],
      trackListError: false,
      playingTrack: -1
    }
  }

  componentDidMount() {
    this.initSoundcloud()
      .then(() => {
        this.widget = window.SC.Widget(this.widgetEl.current)
        this.widget.bind(window.SC.Widget.Events.PLAY, this.handlePlay)
        this.widget.bind(window.SC.Widget.Events.PAUSE, this.props.onPause)
        this.widget.bind(window.SC.Widget.Events.READY, this.handleLibLoaded)
      })
  }

  componentWillUnmount() {
    window.clearTimeout(this.tracklistRetry)
  }

  play = () => {
    this.widget.play();
  }

  pause = () => {
    this.widget.pause();
  }

  handlePlay = (e) => {
    this.props.onPlay()
    this.setState({
      playingTrack: e.soundId
    })
  }

  handleReady = () => {
    if (this.retries >= MAX_TRACKLIST_TRIES) {
      console.log('Tracklist retrieval retries exceeded!')
      this.setState({
        trackListError: true
      })
      return;
    }

    console.log('Trying to get tracklist from soundcloud...')

    this.widget.getSounds(res => {
      if(res.length === 1){
        // Single track player, tracklist does not matter
        return;
      }

      for (const item of res) {
        if (!item.title) {
          this.retries += 1;
          this.tracklistRetry = window.setTimeout(this.handleReady, 200);
          return;
        }
      }
      console.log('Tracklist OK', res);
      this.setState({
        sounds: res.map(sound => ({
          title: sound.title,
          id: sound.id
        }))
      })
    })

    this.props.onReady();
  }

  initSoundcloud = () => new Promise((resolve, reject) => {
    if (window.SC) {
      resolve();
      return;
    }
    const script = document.createElement("script");
    script.src = 'https://w.soundcloud.com/player/api.js';
    script.async = true;
    script.onload = resolve;
    script.onabort = reject;
    script.onerror = reject;

    document.body.appendChild(script);
  });

  handleLibLoaded = () => {
    this.libLoaded = true;
    if (this.libLoaded && this.frameLoaded) {
      this.handleReady();
    }
  }

  handleFrameLoaded = () => {
    this.frameLoaded = true;
    if (this.libLoaded && this.frameLoaded) {
      this.handleReady();
    }
  }

  handleTrackClick = e => {
    const index = Number(e.target.getAttribute("data-index"));
    this.widget.skip(index)
    this.widget.play()
  }

  render() {
    const {url, multi, title} = this.props
    const {sounds, trackListError, playingTrack} = this.state;
    return (
      <>
        <div className="audio-post-title">{title}</div>
        {/* SOUNDCLOUD WIDGET  */}
        {url &&
        <iframe
          allow="autoplay"
          className="soundcloud-widget"
          width="100%"
          onLoad={this.handleFrameLoaded}
          style={{height: multi ? MULTI_HEIGHT : REGULAR_HEIGHT}}
          ref={this.widgetEl}
          src={`https://w.soundcloud.com/player/?url=${encodeURI(url)}&auto_play=false&buying=true&liking=true&download=true&sharing=true&show_artwork=false&show_comments=true&show_playcount=true&show_user=true&hide_related=false&visual=false&start_track=0&callback=true&color=%23ff00ff`}
          frameBorder={0}
        />
        }

        {/* TRACKLIST DISPLAY */}
        {!trackListError && sounds && sounds.length > 1 &&
        <ol className="tracklist">
          {sounds.map((sound, idx) =>
            <li key={idx}>
              <button
                onClick={this.handleTrackClick}
                className={classNames({
                  "link-button": true,
                  "selected": sound.id === playingTrack
                })}
                data-index={idx}
              >
                {sound.title}
              </button>
            </li>
          )}
        </ol>
        }
        {
          trackListError &&
            <div className="tracklist">
              There was a problem loading the tracklist from soundcloud.
            </div>
        }
      </>
    )
  }
}

Player.propTypes = {
  url: PropTypes.string,
  onReady: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  multi: PropTypes.bool
}

Player.defaultProps = {
  url: null,
  multi: false,
  onReady: () => {
  },
  onPlay: () => {
  },
  onPause: () => {
  },
}

export default Player;
