import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Tag extends Component {
  constructor(props) {
    super(props)
  }

  handleClick = () => {
    this.props.onClick(this.props.name)
  }

  render() {
    const {selected, name, separator, index} = this.props
    return (
      <div className="tag-button-container">
        <button
          tabIndex={0}
          className={classNames({
            "link-button": true,
            "mobile-flash" : true,
            selected
          })}
          style={{
            '--index' : index
          }}
          onClick={this.handleClick}
        >
          {name}
        </button>
        {separator && <span className="separator">, </span>}
      </div>
    )
  }
}


Tag.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  separator: PropTypes.bool,
  index: PropTypes.number
}

Tag.defaultProps = {
  selected: false,
  separator: false
}

export default Tag
