import React from "react"
import ReactPlayer from 'react-player'
import PropTypes from "prop-types"

const VideoItem = ({url}) => {
  return (
    <div className="video-item">
      <ReactPlayer
        controls
        className="react-player"
        url={url}
        width="100%"
        height="100%"
      />
    </div>
  )
}

VideoItem.propTypes = {
  url: PropTypes.string.isRequired
}

export default VideoItem
