import React from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image";
import {Helmet} from 'react-helmet'
import {graphql} from 'gatsby'
import {kebabCase, uniq} from 'lodash'
import {navigate} from "@reach/router"
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import Player from "../components/Player";
import Outlink from "../components/Outlink";
import Tag from "../components/Tag";
import Cursor from "../components/Cursor";
import VideoItem from "../components/VideoItem";

const CURSOR_OFFSET = 35 / 2;

class AudioPostTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.cursorRef = React.createRef();
    this.touchOverride = false;

    this.state = {
      playing: false,
      ready: false,
      coverHover: false,
    }
  }

  // In case of a touch device, never try to create the custom cursor
  enableTouchOverride = () => {
    this.touchOverride = true;
  }

  handlePlayerReady = () => {
    this.setState({ready: true})
  }

  handlePlay = () => {
    this.setState({playing: true})
  }

  handlePause = () => {
    this.setState({playing: false})
  }

  startCoverHover = (e) => {
    if (this.touchOverride) return;
    console.log('start cover hover')
    this.setState({coverHover: true})

  }

  endCoverHover = () => {
    console.log('end cover hover')
    this.setState({coverHover: false})
  }


  handleMouseMove = ({clientX, clientY}) => {
    if (this.touchOverride) return;
    if (this.cursorRef.current) {
      this.cursorRef.current.style.left = `${clientX - CURSOR_OFFSET}px`
      this.cursorRef.current.style.top = `${clientY - CURSOR_OFFSET}px`
    } else {
      this.setState({coverHover: true}, () => {
        this.cursorRef.current.style.left = `${clientX - CURSOR_OFFSET}px`
        this.cursorRef.current.style.top = `${clientY - CURSOR_OFFSET}px`
      })
    }
  }


  handleCoverClick = () => {
    if (this.state.ready) {
      if (!this.state.playing) {
        this.playerRef.current.play();

      } else {
        this.playerRef.current.pause();
      }
    }
  }

  handleTagSelect = tag => {
    navigate(`/tags/${kebabCase(tag)}`)
      .catch(e => {
        console.error(e)
        console.log('Could not navigate to tag')
      })
  }

  listTags = () => {
    const tags = this.props.tags.filter(t => t);
    const items = []

    uniq(tags).forEach((tag, idx) => {
      items.push(
        <Tag
          index={idx}
          name={tag}
          key={tag}
          onClick={this.handleTagSelect}
          separator={idx !== tags.length - 1}
        />
      )
    })

    return items
  }

  render() {
    const {
      content,
      contentComponent,
      title,
      helmet,
      image,
      soundcloud,
      bandcamp,
      videos,
      artist,
      tags
    } = this.props;
    const {
      ready,
      playing,
      coverHover
    } = this.state;

    const PostContent = contentComponent || Content

    return (
      <section>
        {helmet || ''}
        <div
          onTouchStart={this.enableTouchOverride}
          onClick={this.handleCoverClick}
          onMouseEnter={this.startCoverHover}
          onMouseLeave={this.endCoverHover}
          onMouseMove={this.handleMouseMove}
          className="cover-wrapper"
        >
          <Img
            fluid={image.childImageSharp.fluid}
            alt={`Cover for ${title}`}
            className="post-cover"
          />
        </div>

        {coverHover &&
        <div
          ref={this.cursorRef}
          className="cursor-container"
        >
          <Cursor ready={ready} playing={playing}/>
        </div>
        }

        <div>
          <Player
            url={soundcloud}
            ref={this.playerRef}
            onReady={this.handlePlayerReady}
            onPlay={this.handlePlay}
            onPause={this.handlePause}
            title={artist ? `${artist} - ${title}` : title}
          />
        </div>

        <div className="page-content two-panel-layout">

          <div className="split-panel">
            <PostContent content={content}/>
          </div>

          <div className="split-panel right">
            {tags &&
            <>
              <div className="audio-post-tags"> {this.listTags()}</div>
              <br/>
            </>
            }
            <div>
              {bandcamp &&
              <Outlink href={bandcamp}>Purchase</Outlink>
              }
            </div>
          </div>
        </div>
        <div>
          {videos && videos.map(v => <VideoItem
            url={v.url}
            key={v.url}
          />)}
        </div>
        <div className="player-dummy"/>
      </section>
    )
  }
}

AudioPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  category: PropTypes.string,
  tags: PropTypes.array,
  artist: PropTypes.string
}

AudioPostTemplate.defaultProps = {
  tags: null,
  artist: null
}

const AudioPost = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <Layout backButton>
      <AudioPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        soundcloud={post.frontmatter.soundcloud}
        bandcamp={post.frontmatter.bandcamp}
        image={post.frontmatter.coverimage}
        tags={post.frontmatter.tags}
        artist={post.frontmatter.artist}
        videos={post.frontmatter.videos}
        category={post.frontmatter.category}
        helmet={
          <Helmet>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.excerpt}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

AudioPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default AudioPost

export const pageQuery = graphql`
  query AudioPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(format: PLAIN, pruneLength: 200)
      html
      frontmatter {
        coverimage {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        title
        tags
        artist
        description
        soundcloud
        bandcamp
        category
        videos {
          url
        }
      }
    }
  }
`
