import React, {Component} from "react"
import PropTypes from "prop-types";

const LINE_WIDTH = 4;
const WIDTH = 35;
const HEIGHT = 35;
const OFFSET = 5;

class Cursor extends Component {

  constructor(props) {
    super(props)
    this.canvasEl = React.createRef()
    this.animationStart = undefined;
  }

  componentDidMount() {
    window.requestAnimationFrame(this.draw)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.playing !== this.props.playing) || (prevProps.ready !== this.props.ready)) {
      this.animationStart = undefined;
      window.requestAnimationFrame(this.draw)
    }
  }

  drawPlayCursor = (ctx, width, height, offset, progress) => {
    ctx.lineJoin = "round"
    ctx.beginPath()
    ctx.moveTo(offset + (height * (1 - progress)), offset)
    ctx.lineTo(width - offset, (height) / 2)
    ctx.lineTo(offset, (height * progress) - offset)
    ctx.lineTo(offset, offset + (height * (1 - progress)))
    if (progress >= 1) {
      ctx.closePath()
    }
    ctx.stroke()
  }

  drawPauseCursor = (ctx, width, height, offset, progress) => {
    ctx.lineJoin = "round"
    ctx.strokeRect(offset, offset, width / 4, (height - offset * 2) * progress)
    ctx.strokeRect(offset + width / 2, offset, (width / 4) * progress, height - offset * 2)
  }

  drawLoadingAnimation = (ctx, width, height, offset, progress) => {
    ctx.arc(width / 2,
      height / 2,
      width - offset * 4,
      (2 * Math.PI) * progress - (Math.PI),
      (2 * Math.PI) * progress)
    ctx.stroke()
  }

  draw = (timestamp) => {
    if (this.animationStart === undefined) {
      this.animationStart = timestamp;
    }

    const {ready, playing} = this.props;

    const elapsed = timestamp - this.animationStart

    let progress = elapsed * 0.003;

    if (progress >= 1 && ready) {
      progress = 1
    }

    const canvas = this.canvasEl.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d');

    const width = WIDTH * window.devicePixelRatio;
    const height = HEIGHT * window.devicePixelRatio;
    const offset = OFFSET * window.devicePixelRatio;

    canvas.style.width = `${WIDTH}px`;
    canvas.style.height = `${HEIGHT}px`;

    canvas.width = width;
    canvas.height = height;


    ctx.strokeStyle = `rgb(0, 255, 0)`;
    ctx.lineWidth = LINE_WIDTH;

    if (!ready) {
      this.drawLoadingAnimation(ctx, width, height, offset, progress)
    } else if (playing) {
      this.drawPauseCursor(ctx, width, height, offset, progress)
    } else {
      this.drawPlayCursor(ctx, width, height, offset, progress)
    }

    if (progress < 1 || !ready) {
      window.requestAnimationFrame(this.draw)
    }
  }

  render() {
    return (
      <canvas
        ref={this.canvasEl}
        className="cursor-canvas"
      />
    )
  }
}

Cursor.propTypes = {
  ready: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired
}

export default Cursor
